document.addEventListener("DOMContentLoaded", () => {
  const body = document.querySelector("body");
  const burgerMenu = document.querySelector(".navigation-burger-menu");
  const siteContainer = document.querySelector(".site-container");
  const mobileNavContainer = document.querySelector(".mobile-nav-container");
  const navItem = mobileNavContainer.querySelectorAll(".nav-item");
  const siteContainerBlackAlpha = document.querySelector(".site-container-black-alpha");
  const alphaCount = 0;

  closeNav();

  burgerMenu.addEventListener('click', () => {
    if (siteContainer.classList.contains('navigation-open')) {
      closeNav();
    } else {
      openNav();
    }
    siteContainer.classList.toggle("navigation-open");
  });

  siteContainerBlackAlpha.addEventListener('click', () => {
    closeNav();
    siteContainer.classList.remove("navigation-open");
  });

  function closeNav() {
    for (let i = 0; i < alphaCount; i++) {
      let siteContainerAlpha = document.querySelector(`.site-container-alpha${i + 1}`);
      siteContainerAlpha.style.transition = 'all 1s';
      siteContainerAlpha.style.transform = 'scale(0.5) translateX(-90vw)';
      siteContainerAlpha.style.opacity = '0';
    }

    body.style.position = 'initial';
    siteContainerBlackAlpha.style.transform = 'translateX(-50vw)'

    siteContainer.style.transition = '0.5s'
    siteContainer.style.transform = 'translateY(0) translateX(0)';
    siteContainer.removeAttribute('style');
  }

  function openNav() {
    for (let i = 0; i < alphaCount; i++) {
      let siteContainerAlpha = document.querySelector(`.site-container-alpha${i + 1}`);
      let zIndex = alphaCount - (i + 1);
      let right = 70 - (i * 4);
      let scaleY = 91 - (i * 4);
      siteContainerAlpha.style.transition = `transform ${0.2 + (i * 0.8)}s, filter 2s`;
      siteContainerAlpha.style.zIndex = zIndex;
      siteContainerAlpha.style.transform = `scaleY(${scaleY}%) translateX(-${right}vw)`;
      siteContainerAlpha.style.opacity = `0.${60 - (i * 15)}`;
    }

    body.style.position = 'fixed';
    siteContainerBlackAlpha.style.transform = 'translateX(0)'
    siteContainer.style.transition = 'transition: 0.5s, filter 0s, transform 0.5s';
    siteContainer.style.transform = 'translateX(-75vw)';
  }

  const dropDownNavLinks = document.querySelectorAll(".dropdown-nav-link");
  const navLinksDropdowns = document.querySelectorAll(".nav-links-dropdown");

  dropDownNavLinks.forEach((link) => {
    link.addEventListener('click', () => {
      link.classList.toggle("dropdown-nav-link-active");

      let dropdownMenu = link.nextElementSibling;
      if (!dropdownMenu.classList.contains('d-none')) {
        dropdownMenu.classList.add('d-none');
      } else {
        navLinksDropdowns.forEach((dropdown) => {
          dropdown.classList.add('d-none');
        });

        dropdownMenu.classList.toggle('d-none');
      }
    });
  });
});
